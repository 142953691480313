<template>
    <div class="popUpForm popUpForm_stb" v-loading="loading">
        <div class="content" :class="{'attachmentView': formType == 'enclosure' || !isMaintain}">
            <el-tabs v-model="activeName" @tab-click="formSwitch">
                <template v-for="(item, index) in navData">
                    <el-tab-pane
                        :name="`page_type_${item.page_type}`"
                        :key="`formNav_${index}`"
                        :disabled="item.page_type === 'enclosure' && isSave"
                    >
                        <span slot="label" v-if="item.page_type != 'enclosure'">
                            <i class="iconfont iconbiaodan"></i>{{ item.page_name }}
                        </span>
                        <span slot="label" v-else>
                            <i class="iconfont iconwenjianjia"></i>附件资料
                        </span>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <formPage
                v-if="formType != 'enclosure'"
                ref="formData"
                :code="codeVal"
            ></formPage>
            <div
                v-if="formType == 'enclosure'"
                class="foneContent"
                :class="{'foneContentStyle': formType === '0'}"
                :is="pagePath"
                :form-data="formData"
                :extr="extr"
                :business-instance-id="businessInstanceId"
                :is-read="true"
            ></div>
        </div>
        <footer class="footer_box" v-if="formType != 'enclosure' && isMaintain">
            <el-button
                type="primary"
                class="save"
                size="mini"
                @click="save"
                :loading="saveBtnLoading"
            >
                保存
            </el-button>
            <el-button
                type="primary"
                class="save"
                size="mini"
                @click="Printing"
            >
                打印
            </el-button>
            <el-button
                class="Close"
                size="mini"
                @click="Close"
            >
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';
import formPage from './forms/index_stb.vue';
export default {
    components: { formPage },
    props: {
        code: {
            type: [Array, Object, String, Function],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            isSave: true,
            // 页面数据集合
            pageData: [],
            // tab数据
            navData: [
                {
                    page_name: '合同基本信息',
                    page_type: 'contract_detail',
                },
                {
                    page_name: '附件资料',
                    page_type: 'enclosure',
                },
            ],
            // 默认展开tab页
            activeName: 'page_type_contract_detail',
            // 页面路径
            pagePath: '',
            // 表单类型，0 配置表单，1vue表单
            formType: '',
            // 配置表单id
            frameId: '',
            // 配置表单传入参数
            formData: {
                FormUrl: '',
                FormKeyValue: '',
                IsRead: true,
            },
            // 自定义表单传入参数
            extr: {
                contract_number: '',
            },
            // 流程id 附件传入参数
            businessInstanceId: '',
            // 是否是维护
            isMaintain: null,
            // 保存按钮状态
            saveBtnLoading: false,
            // 合同信息
            codeVal: null,
        };
    },
    async created() {
        this.codeVal = this.code;
        // 合同台账维护
        if (this.code?.viewScene === 'maintain' || this.code?.viewScene === 'add') {
            this.isMaintain = true;
        } else {
            this.isMaintain = null;
        }
        if (this.code.contractNumber) {
            this.extr.contract_number = this.code.contractNumber;
            this.loading = true;
            this.isSave = false;
            this.businessInstanceId = this.code.cvid;
            // this.pageData = await this.getformData();
            // if (Object.keys(this.pageData).length > 0) {
            //     this.processingFormData(this.pageData);
            // }
            this.loading = false;
        } else {
            this.isSave = true;
        }
    },
    methods: {
        // 处理表单数据
        processingFormData(data) {
            // this.navData.push(...data.pageList, {
            //     page_name: '附件资料',
            //     page_type: 'enclosure',
            // });
            if (this.isMaintain) {
                this.formData.IsRead = false;
                this.navData = this.navData.filter(item => !item.page_url || item.page_url.indexOf('salesBusiness/') === -1);
                this.navData.forEach(item => {
                    if (item.page_url) {
                        item.page_url = `${item.page_url}&ViewCode=adjustmonomer&RightGroupCode=adjustmonomer`;
                    }
                });
            }
            this.businessInstanceId = data.business_instance_id;
            this.activeName = `page_type_${data.pageList[0].page_type}`;
            this.processFormPath(data.pageList[0]);
        },

        // 表单切换
        formSwitch(tabs) {
            const row = this.navData[tabs.index];
            this.processFormPath(row);
        },

        // 处理表单路径
        processFormPath(row) {
            let myComponent = {};
            this.formType = row.page_type;
            if (row.page_type === '0') {
                myComponent = () => import('./forms/index_stb.vue');
                this.formData.FormUrl = row.page_url;
                this.formData.FormKeyValue = row.key_value;
            } else if (row.page_type === '1') {
                myComponent = () => import('@/views/' + row.page_url + '.vue');
                this.extr.formInfo = row;
            } else if (row.page_type === 'enclosure') {
                myComponent = () => import('./annex/index_stb.vue');
            }
            this.frameId = `frameId${this.$queryString(this.formData.FormUrl, 'FormCode')}`;
            this.pagePath = myComponent;
        },

        // 保存表单
        save() {
            this.$refs.formData.$refs.form.validate(valid => {
                if (valid) {
                    if (!this.$refs.formData.userData || this.$refs.formData.userData.length === 0) {
                        this.$message.error('请添加要料人');
                        return false;
                    }
                    let flag = true;
                    this.$refs.formData.userData.forEach(item => {
                        if (!item.want_material_phone || !item.want_material_name) {
                            flag = false;
                        }
                    });
                    if (flag) {
                        this.saveDataFun();
                        this.loading = true;
                        this.saveBtnLoading = true;
                    } else {
                        this.$message.error('要料人姓名跟要料人电话必填');
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 保存接口
        saveDataFun() {
            const { check, contract_number, ...other } = this.$refs.formData.form;
            const obj = { ...other };
            const users = this.$refs.formData.userData;
            obj.WantMaterialList = users;
            console.log(check, contract_number);
            // if (check) {
            //     obj.contract_number = this.$refs.formData.prefixVal + contract_number;
            // }
            this.$axios.post('/interfaceApi/sale/contract_manager/base_contract_save', obj).then(res => {
                if (res) {
                    this.$message.success('保存成功！');
                    this.codeVal.cvid = res.cvid;
                    this.businessInstanceId = res.cvid;
                    this.loading = false;
                    this.saveBtnLoading = false;
                    this.isSave = false;
                    this.pagePath = false;
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$parent.hide();
                }
            }).catch(error => {
                this.$message.error(error);
                this.loading = false;
                this.saveBtnLoading = false;
            });
        },
        // 保存失败回调
        errorCallback() {
            this.saveBtnLoading = false;
            this.loading = false;
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
        // 打印
        Printing() {
            const iframe = window.document.getElementById(this.frameId);
            iframe.contentWindow.JyFormPrintForm();
        },

        // 获取流程表单数据
        getformData() {
            return this.$axios.$get(`/interfaceApi/sale/contract_manager/contract/current_page_data/${this.code.contractNumber}`, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" scope>
.popUpForm_stb
    .content
        .el-tabs
            height 0.52rem
        .main_box
            height: calc(100% - 0.52rem);
</style>
